import React from 'react'
import './member-verification.css'

export default function MemberVerificationHero() {
    return (
        <>

            <div className='member-verification_hero px-[22px] lg:px-[108px] flex items-center'>
                <div className='text-white font-bold text-[30px] lg:text-[60px] mt-12 lg:mt-0'>MEMBER VERIFICATION</div>
            </div>
        </>
    )
}

import React from 'react'

export default function OurTeamHero() {
    return (
        <>
            <div className='contact_hero px-[22px] lg:px-[108px] flex items-center'>
                <div className='text-white font-bold text-[30px] lg:text-[60px] mt-12 lg:mt-0'>OUR TEAM</div>
            </div>
        </>
    )
}

import React from 'react'

export default function AboutSection() {
    return (
        <>
            <div className='px-[22px] py-[60px] lg:px-[108px] lg:py-[90px] flex justify-between items-center lg:flex-row flex-col'>
                <div className='lg:w-[520px] xl:w-[520px] 2xl:w-[720px] lg:pr-10 xl:pr-4 2xl:pr-0'>
                    <img className='custom_about_img' src="./assets/images/about-us-page/about-blob-1.png" alt="" />
                </div>
                <div className='lg:w-[530px] xl:w-[530px] 2xl:w-[720px]  space-y-4'>
                    <div className='font-bold text-theme-black tracking-wide lg:text-lg xl:text-xl custom_about_heading mt-4 lg:mt-0'>About Us</div>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_sub_heading'>Who Are WE</div>
                    <div className='text-[#230E0E] opacity-90 text-justify xl:w-[94%] lg:w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <span className=''> At the heart of the Kingdom of Saudi Arabia, our Chitrali Community Hub stands as a testament to
                            the strength and unity of the Chitrali community from Pakistan. Bound by shared values and a spirit
                            of solidarity, our platform is a place where individuals can register, connect, and contribute to the
                            well-being of our community members.</span>

                        <span className=''><b>Our mission</b> is simple yet powerful – to foster a sense of belonging and
                            support within the Chitrali community in Saudi Arabia. We are committed to
                            promoting the welfare of our members through initiatives centered around
                            health, education, security, and emergency assistance. At the core of our
                            values lie compassion, collaboration, and a shared responsibility towards
                            one another.</span>
                    </div>
                </div>
            </div>
        </>
    )
}

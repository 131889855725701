import React, { useState } from 'react'
import './support-section.css'
import { RiGraduationCapFill } from "react-icons/ri";
import { FaDroplet } from "react-icons/fa6";
import { BiSolidFirstAid } from "react-icons/bi";
import { PiBowlFoodBold } from "react-icons/pi";
import { FaHandshake } from "react-icons/fa6";

// import TheSupportCard from './TheSupportCard';

export default function SupportSection() {

    const [hoverState, setHoverState] = useState({
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false
    });

    const handleMouseEnter = (card) => {
        setHoverState(prevState => ({
            ...prevState,
            [card]: true
        }));
    }

    const handleMouseLeave = (card) => {
        setHoverState(prevState => ({
            ...prevState,
            [card]: false
        }));
    }


    // const [cardData, setCardData] = useState([]);

    // useEffect(() => {
    //     // Fetch data from the backend
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch("/support-section.json");
    //             const data = await response.json();
    //             setCardData(data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };

    //     fetchData();
    // }, []);
    return (
        <>
            <div className='main_support px-[22px] py-[15px] lg:px-[108px] lg:py-[104px]'>
                <div className='lg:text-2xl xl:text-4xl 2xl:text-4xl text-center mt-4  lg:mb-16 text-white font-extrabold uppercase tracking-wider lg:px-[140px] 2xl:px-[300px]'>Happiness Doesn’t Result From What We Get, But From What We Give</div>
                <div className='flex justify-between items-center lg:flex-nowrap flex-wrap'>

                    {/* {cardData.map((item) => {
                        return (
                            <TheSupportCard key={item._id} item={item} />
                        )
                    })} */}

                    <div className='w-[163px] h-[125px] lg:w-[145px] lg:h-[130px] xl:w-[195px] xl:h-[150px] 2xl:w-[275px] 2xl:h-[225px] bg-white rounded-[13.33px] relative flex items-center justify-center mt-8 lg:mt-0'

                        onMouseEnter={() => handleMouseEnter('card1')}
                        onMouseLeave={() => handleMouseLeave('card1')}>

                        <div className={` ${hoverState.card1 ? 'scale-150' : ''} absolute bg-theme-black p-2 lg:p-2 2xl:p-4 rounded-full -top-5 lg:-top-5 2xl:-top-10 lg:left-[50px] xl:left-[70px] 2xl:left-[90px] duration-300 `}>
                            {/* <img className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12' src={item.icon} alt="" /> */}
                            <PiBowlFoodBold className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12 text-white' />

                        </div>
                        <div className='text-theme-black font-bold text-lg lg:text-base xl:text-xl 2xl:text-2xl px-10 lg:px-8 xl:px-16 2xl:px-20   text-center leading-5 lg:leading-4 xl:leading-5 2xl:leading-7'>Culture
                            Promotion</div>

                    </div>

                    <div className='w-[163px] h-[125px] lg:w-[145px] lg:h-[130px] xl:w-[195px] xl:h-[150px] 2xl:w-[275px] 2xl:h-[225px] bg-white rounded-[13.33px] relative flex items-center justify-center mt-8 lg:mt-0'

                        onMouseEnter={() => handleMouseEnter('card2')}
                        onMouseLeave={() => handleMouseLeave('card2')}>

                        <div className={` ${hoverState.card2 ? 'scale-150' : ''} absolute bg-theme-black p-2 lg:p-2 2xl:p-4 rounded-full -top-5 lg:-top-5 2xl:-top-10 lg:left-[50px] xl:left-[70px] 2xl:left-[90px] duration-300 `}>
                            {/* <img className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12' src={item.icon} alt="" /> */}
                            <FaHandshake className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12 text-white' />
                        </div>
                        <div className='text-theme-black font-bold text-lg lg:text-base xl:text-xl 2xl:text-2xl px-10 lg:px-8 xl:px-16 2xl:px-20   text-center leading-5 lg:leading-4 xl:leading-5 2xl:leading-7'>Mutual
                            Help</div>

                    </div>



                    <div className='w-[163px] h-[125px] lg:w-[145px] lg:h-[130px] xl:w-[195px] xl:h-[150px] 2xl:w-[275px] 2xl:h-[225px] bg-white rounded-[13.33px] relative flex items-center justify-center mt-8 lg:mt-0'

                        onMouseEnter={() => handleMouseEnter('card3')}
                        onMouseLeave={() => handleMouseLeave('card3')}>

                        <div className={` ${hoverState.card3 ? 'scale-150' : ''} absolute bg-theme-black p-2 lg:p-2 2xl:p-4 rounded-full -top-5 lg:-top-5 2xl:-top-10 lg:left-[50px] xl:left-[70px] 2xl:left-[90px] duration-300 `}>
                            {/* <img className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12' src={item.icon} alt="" /> */}
                            <RiGraduationCapFill className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12 text-white' />

                        </div>
                        <div className='text-theme-black font-bold text-lg lg:text-base xl:text-xl 2xl:text-2xl px-10 lg:px-8 xl:px-16 2xl:px-20   text-center leading-5 lg:leading-4 xl:leading-5 2xl:leading-7'>Education Promotion</div>

                    </div>

                    <div className='w-[163px] h-[125px] lg:w-[145px] lg:h-[130px] xl:w-[195px] xl:h-[150px] 2xl:w-[275px] 2xl:h-[225px] bg-white rounded-[13.33px] relative flex items-center justify-center mt-8 lg:mt-0'

                        onMouseEnter={() => handleMouseEnter('card4')}
                        onMouseLeave={() => handleMouseLeave('card4')}>

                        <div className={` ${hoverState.card4 ? 'scale-150' : ''} absolute bg-theme-black p-2 lg:p-2 2xl:p-4 rounded-full -top-5 lg:-top-5 2xl:-top-10 lg:left-[50px] xl:left-[70px] 2xl:left-[90px] duration-300 `}>
                            {/* <img className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12' src={item.icon} alt="" /> */}
                            <FaDroplet className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12 text-white' />

                        </div>
                        <div className='text-theme-black font-bold text-lg lg:text-base xl:text-xl 2xl:text-2xl px-10 lg:px-8 xl:px-16 2xl:px-20   text-center leading-5 lg:leading-4 xl:leading-5 2xl:leading-7'>Enterprise
                            Support</div>

                    </div>

                    <div className='w-[163px] h-[125px] lg:w-[145px] lg:h-[130px] xl:w-[195px] xl:h-[150px] 2xl:w-[275px] 2xl:h-[225px] bg-white rounded-[13.33px] relative flex items-center justify-center mt-8 lg:mt-0'

                        onMouseEnter={() => handleMouseEnter('card5')}
                        onMouseLeave={() => handleMouseLeave('card5')}>

                        <div className={` ${hoverState.card5 ? 'scale-150' : ''} absolute bg-theme-black p-2 lg:p-2 2xl:p-4 rounded-full -top-5 lg:-top-5 2xl:-top-10 lg:left-[50px] xl:left-[70px] 2xl:left-[90px] duration-300 `}>
                            {/* <img className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12' src={item.icon} alt="" /> */}
                            <BiSolidFirstAid className='h-6 w-6 lg:h-8 lg:w-8 2xl:h-12 2xl:w-12 text-white' />

                        </div>
                        <div className='text-theme-black font-bold text-lg lg:text-base xl:text-xl 2xl:text-2xl px-10 lg:px-8 xl:px-16 2xl:px-20   text-center leading-5 lg:leading-4 xl:leading-5 2xl:leading-7'>Healthcare
                            Support</div>

                    </div>





                </div>
            </div>
        </>
    )
}

import React, { useEffect } from 'react'
import AboutUsHero from '../components/AboutUSComponents/AboutUsHero'
import AboutSection from '../components/AboutUSComponents/AboutSection'
import OurAimSection from '../components/AboutUSComponents/OurAimSection'
import JoinUsSection from '../components/AboutUSComponents/JoinUsSection'
import CommunitySupportSection from '../components/AboutUSComponents/CommunitySupportSection'


export default function AboutUsPage() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <AboutUsHero />
            <AboutSection />
            <OurAimSection />
            <JoinUsSection />
            <CommunitySupportSection />
        </>
    )
}

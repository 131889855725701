import React from 'react'

export default function OurTeamMembers() {
    return (
        <>
            <div className='py-[30px] px-[22px] lg:px-[108px] lg:py-[30px]'>
                <div className='font-bold tracking-wide text-base lg:text-lg 2xl:text-2xl'>Team Member</div>
                <div className='font-bold text-[25px] lg:text-[30px] xl:text-[40px] 2xl:text-[50px] tracking-wide mt-2 lg:mt-3'>Team Behind Overseas Chirtalis Association</div>
            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/home-page/cabinet-section/cabinet-1.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Haider Nawaz</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Chairman</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/rustam.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Rustam Shah</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Vice President</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/majeed.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Abdul Majeed</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>General Secretary</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/siyana.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Siyana khan</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Shura Member</p>
                </div>
            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/khaliq.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Abdul Khaliq</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Emergency Donor Secretary</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/rafi.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Rafi Uddin</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Finance Secretary</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/misbah.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Misbah Uddin</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Cheif of Finance</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/sadiq.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Sadiq</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Office Secretary</p>
                </div>
            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/jamil.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Jamil UR Rehman</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Information Secretary</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/moeen.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Moeen Uddin</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Shura Member</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/azamm.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Azam khan</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Shura Member</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/hasan.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Hassan Syed</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Social Media Secretary</p>
                </div>
            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/mukhtar.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Mukhtar UR Rehman</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Development Secretary</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/hussain.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>DR. Hussain Ali Shah</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Shura Member</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/murad.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Ali Murad</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Emergency Jirga Secretary</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/1.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Hafiz Ullah</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator</p>
                </div>

            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/2.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Ishaq</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Albaha</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/3.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Didar ishaq</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Riyadh</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/4.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Nizam Uddin</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Hali Sufa</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/5.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Dildar Muhammad</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Riyadh ABV</p>
                </div>

            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/6.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Abdul Manan Rechvi</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Makkah</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/7.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Jahangir khan</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator khamis</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/8.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Amjad Ali</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/9.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Mumtaz uddin</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Riyadh</p>
                </div>

            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/10.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Mujeeb Ullah</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Admins</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/11.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Hidayat Ullah</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Jazan</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/12.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Haider Wali Taj</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Riyadh</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/13.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Riyaz Uddin</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Makkah</p>
                </div>

            </div>

            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>

                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/14.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Ahmed Qadir</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Makkah</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/15.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Qayum Ahmad</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Taif</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/16.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Qamar Ali</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Tabuk</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/team-member/17.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Wali Ur Rehman</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Co-ordinator Riyadh</p>
                </div>

            </div>



        </>
    )
}

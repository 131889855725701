import React from 'react'
import { Link } from 'react-router-dom'

export default function HelpCard({ item }) {
    return (
        <>
            <div className='w-[162px] h-[210px] lg:w-[190px] lg:h-[240px] xl:w-[241px] xl:h-[282px] 2xl:w-[321px] 2xl:h-[362px] bg-white rounded-md text-center px-3 py-4 lg:py-[31px] lg:px-[19px] mt-5 lg:mt-0'>
                <div className=''>
                    <img className='mx-auto 2xl:w-[58px] 2xl:h-[64px] xl:w-[38px] xl:h-[44px] lg:w-[30px] lg:h-[36px] w-[30px] h-[35px]' src={item.icon} alt="" />
                </div>
                <div className='mt-6 font-bold text-theme-black tracking-wide 2xl:text-3xl xl:text-lg lg:text-base text-base'>{item.name}</div>
                <div className='mt-3 text-stone-900 text-opacity-60 2xl:text-xl xl:text-sm lg:text-xs text-xs xl:px-4 px-0'>{item.description}</div>
                <Link to={item.linkPage}>
                    <button className='mt-2 lg:mt-6 2xl:mt-12 bg-theme-black text-white rounded-lg 2xl:py-[16px] 2xl:px-[24px] xl:py-[8px] xl:px-[18px] lg:py-[6px] lg:px-[15px] px-3 py-2 2xl:text-xl lg:text-sm text-sm hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer'>
                        {item.buttonText}
                    </button>
                </Link>
            </div>
        </>
    )
}

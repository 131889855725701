import React, { useEffect } from 'react'
import OurTeamHero from '../components/OurTeamComponents/OurTeamHero'
import OurTeamMembers from '../components/OurTeamComponents/OurTeamMembers'

export default function OurTeam() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <OurTeamHero />
            <OurTeamMembers />
        </>
    )
}

import React from 'react'

export default function CommunitySupportSection() {
    return (
        <>
            <div className='px-[22px] py-[60px] lg:px-[108px] lg:py-[104px] flex justify-between lg:flex-row flex-col'>
                <div className='lg:w-[520px] xl:w-[520px] 2xl:w-[720px] lg:pr-10 xl:pr-4 2xl:pr-0'>
                    <img className='custom_about_img' src="./assets/images/about-us-page/about-blob-3.png" alt="" />
                </div>
                <div className='lg:w-[530px] xl:w-[530px] 2xl:w-[720px] space-y-4  lg:space-y-4 2xl:space-y-6'>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_heading'>Community Support</div>
                    <div className='text-[#230E0E] opacity-90 text-justify w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <span className=''>Beyond the specific focus areas, our platform serves as a space
                            for general community support. Whether it's celebrating achievements, sharing news,
                            or offering a helping hand during difficult times, we are committed to creating an
                            environment where every Chitrali member feels valued and connected.</span>

                        <span className=''>Join us at Chitrali Community Hub – where your presence matters,
                            and your support makes a difference. Together, we thrive, support, and build a
                            stronger Chitrali community in the Kingdom of Saudi Arabia.</span>
                    </div>
                    <button className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white lg:text-base 2xl:text-xl xl:text-base 2xl:px-[41px] 2xl:py-[19px] xl:px-[31px] xl:py-[13px] lg:px-[21px] lg:py-[8px] px-[31px] py-[14px] rounded-lg tracking-wide'>Join Now</button>
                </div>

            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import './help-section.css'
import HelpCard from './HelpCard'

export default function TheHelpSection() {
    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        // Fetch data from the backend
        const fetchData = async () => {
            try {
                const response = await fetch("/help-section.json");
                const data = await response.json();
                setCardData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <div className='main_help px-[22px] py-[15px] lg:px-[108px] lg:py-[104px]'>
                <div className='text-2xl lg:text-4xl 2xl:text-[50px] text-center mt-4 lg:mt-4 mb-4 lg:mb-8 2xl:mb-12 text-white font-extrabold uppercase tracking-wider'>How could you help?</div>
                <div className='flex justify-between items-center lg:flex-nowrap flex-wrap'>

                    {cardData.map((item) => {
                        return (
                            <HelpCard key={item._id} item={item} />
                        )
                    })}

                </div>
            </div>
        </>
    )
}

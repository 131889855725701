import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TheNavbar from './components/TheNavbar';
import HomePage from './Pages/HomePage';
import TheFooter from './components/TheFooter';
import ContactUsPage from './Pages/ContactUsPage';
import AboutUsPage from './Pages/AboutUsPage';
import MemberVerificationPage from './Pages/MemberVerificationPage';
import TheLogin from './Pages/TheLogin';
import BecomeAMemberPage from './Pages/BecomeAMemberPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import OurTeam from './Pages/OurTeam';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const [fileName, setFileName] = useState('');

  
  useEffect(() => {
    // Check localStorage for user's login state and fileName
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const storedFileName = localStorage.getItem('fileName');

    if (isLoggedIn && storedFileName) {
        setFileName(storedFileName);
    }
}, []);
  return (
    <>
      <BrowserRouter>
        <TheNavbar fileName={fileName} />
        {/* <ToastContainer /> */}
        <ToastContainer
          theme='dark'
        />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path='/contact-us' element={<ContactUsPage />} />
          <Route path='/about-us' element={<AboutUsPage />} />
          <Route path='/member-verification' element={<MemberVerificationPage />} />
          <Route path='/become-a-member' element={<BecomeAMemberPage />} />
          <Route path='/login' element={<TheLogin setFileName={setFileName} />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />

          <Route path='/our-team' element={<OurTeam/>} />

        </Routes>
        <TheFooter />
      </BrowserRouter>
    </>
  )
}



import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
// import { Link } from 'react-router-dom';

export default function ForgotPasswordPage() {

    const [phoneNumber, setPhoneNumber] = useState("")
    const [otp, setOtp] = useState(Array(4).fill('')); // Initialize an array to hold 4 OTP digits

     // Create refs for each OTP input field
     const otpInputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const handlePhoneVerify = async (e) => {

        e.preventDefault()

        // console.log(iqamaNumber)
        setPhoneNumber(phoneNumber)

        const data = {
            phoneNumber: phoneNumber,
        }

        try {
            // console.log("hello from try")
            console.log(data)
        } catch (error) {
            // console.log("hello from catch")
        }
    }

    const handleOTPVerify = (e) => {

        e.preventDefault()

        const data = {
            otp: otp.join(''), // Concatenate the OTP digits into a single string
        };


        try {
            // console.log("hello from try")
            console.log(data)
        } catch (error) {
            // console.log("hello from catch")
        }

    }

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className=' login-page-hero px-[22px] flex lg:hidden items-center'>
                <div className='text-white font-bold text-[30px] mt-12'>Password Reset</div>
            </div>

            <div className=' flex items-center justify-between'>
                <div className=' px-[22px] lg:pl-[128px] flex-initial w-[24rem] lg:flex-initial lg:w-[28rem]  xl:flex-initial xl:w-[35rem] 2xl:flex-initial 2xl:w-[50rem]'>
                    <div className='text-theme-black font-bold text-[20px] lg:text-[30px] 2xl:text-[50px] mt-10 lg:mt-10 2xl:mt-20'>Forgot Password?</div>
                    <div className='text-sm mt-3 lg:text-md 2xl:text-xl text-theme-black'>Enter your Phone Number to reset your Password.</div>

                    {/* login form */}
                    <div className='mt-12'>
                        <label className="form-control relative">
                            <div className="label">
                                <span className="label-text font-bold text-black 2xl:text-lg">Phone Number</span>
                            </div>
                            <input
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                type="number" placeholder="+966 000 0000000" className="input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] py-4 lg:input-md xl:input-md  2xl:input-lg"
                            />
                            <div onClick={handlePhoneVerify} className='absolute bg-theme-black top-[36px] lg:top-[36px] 2xl:top-[45px] right-0 px-5 py-3 lg:px-4 lg:py-3 xl:px-5 xl:py-3 2xl:px-7 2xl:py-5  rounded-md cursor-pointer'><img src="./assets/icons/send-icon.png" alt="" /></div>
                        </label>
                        <div className='flex justify-end items-end mt-3 lg:mt-1 2xl:mt-6'>
                            <div className='underline-offset-2 underline font-bold text-base lg:text-sm xl:text-base 2xl:text-xl mt-1'>
                                Resend
                            </div>
                            <div className='text-sm 2xl:text-lg font-bold text-[#8A8A8A] ps-2 lg:ps-1 2xl:ps-2 tracking-wide'>again in 24s</div>
                        </div>

                        <label className="form-control">
                            <div className="label">
                                <span className="label-text font-bold text-black 2xl:text-lg">OTP</span>
                            </div>
                            <div className='flex justify-between'>
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={otpInputRefs[index]} // Attach ref to the input field
                                        type='number'
                                        className='w-[60px] h-[55px] lg:w-[60px] lg:h-[45px] xl:w-[87px] xl:h-[55px] 2xl:w-[120px] 2xl:h-[77px] bg-[#F9FAFB] rounded-md  2xl:text-2xl px-6 lg:px-6 xl:px-9 2xl:px-12 focus:outline-none'
                                        value={digit}
                                        onChange={(e) => {
                                            const newOtp = [...otp];
                                            const value = e.target.value.slice(-1);
                                            if (!isNaN(value)) {
                                                newOtp[index] = value;
                                                setOtp(newOtp);
                                            }
                                            // Shift focus to the next input field if available
                                            if (index < otp.length - 1 && value !== '') {
                                                otpInputRefs[index + 1].current.focus();
                                            }
                                        }}
                                    />
                                ))}
                                {/* <input
                                    type='number' className='w-[60px] h-[55px] lg:w-[60px] lg:h-[45px] xl:w-[87px] xl:h-[55px] 2xl:w-[120px] 2xl:h-[77px] bg-[#F9FAFB] rounded-md  2xl:text-2xl px-6 lg:px-6 xl:px-9 2xl:px-12 focus:outline-none'
                                    maxLength={1}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 1);
                                    }}
                                />
                                <input
                                    type='number' className='w-[60px] h-[55px] lg:w-[60px] lg:h-[45px] xl:w-[87px] xl:h-[55px] 2xl:w-[120px] 2xl:h-[77px] bg-[#F9FAFB] rounded-md  2xl:text-2xl px-6 lg:px-6 xl:px-9 2xl:px-12 focus:outline-none'
                                    maxLength={1}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 1);
                                    }}
                                />
                                <input
                                    type='number' className='w-[60px] h-[55px] lg:w-[60px] lg:h-[45px] xl:w-[87px] xl:h-[55px] 2xl:w-[120px] 2xl:h-[77px] bg-[#F9FAFB] rounded-md  2xl:text-2xl px-6 lg:px-6 xl:px-9 2xl:px-12 focus:outline-none'
                                    maxLength={1}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 1);
                                    }}
                                />
                                <input
                                    type='number' className='w-[60px] h-[55px] lg:w-[60px] lg:h-[45px] xl:w-[87px] xl:h-[55px] 2xl:w-[120px] 2xl:h-[77px] bg-[#F9FAFB] rounded-md  2xl:text-2xl px-6 lg:px-6 xl:px-9 2xl:px-12 focus:outline-none'
                                    maxLength={1}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 1);
                                    }}
                                /> */}

                            </div>
                        </label>

                        <div onClick={handleOTPVerify} className='bg-theme-black text-white text-center py-[14px]  lg:py-[10px] xl:py-[14px] 2xl:py-[21px] 2xl:text-2xl mt-8 mb-12 lg:mb-0 lg:mt-8 2xl:mt-16 rounded-md cursor-pointer'>Confirm</div>
                    </div>
                </div>
                <div>
                    <img className='hidden lg:block lg:h-[500px] lg:w-[540px] 2xl:h-[700px] 2xl:w-[780px] lg:mt-[88px] 2xl:mt-[130px]' src="./assets/images/login-right-pic.png" alt="" />
                </div>


            </div>
        </>
    )
}

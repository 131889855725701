import React, { useEffect } from 'react'
import MemberVerificationHero from '../components/MemberVerificationComponents/MemberVerificationHero'
import MemberVerificationForm from '../components/MemberVerificationComponents/MemberVerificationForm'

export default function MemberVerificationPage() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <MemberVerificationHero />
            <MemberVerificationForm />
        </>
    )
}

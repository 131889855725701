import React, { useEffect } from 'react'
import HeroSection from '../components/HomePageComponents/HeroSection'
import OurVisionSection from '../components/HomePageComponents/OurVisionSection'
import TheHelpSection from '../components/HomePageComponents/TheHelpSection/TheHelpSection'
import AboutUsSection from '../components/HomePageComponents/AboutUsSection'
import SupportSection from '../components/HomePageComponents/SupportSection/SupportSection'
import TheCabinet from '../components/HomePageComponents/TheCabinet'

export default function HomePage() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <HeroSection />
            <OurVisionSection />
            <TheHelpSection />
            <AboutUsSection />
            <SupportSection />
            <TheCabinet />
        </>
    )
}

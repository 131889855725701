import React, { useEffect } from 'react'
import ContactUsHero from '../components/ContactUsComponents/ContactUsHero'
import ContactUsForm from '../components/ContactUsComponents/ContactUsForm'

export default function ContactUsPage() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <ContactUsHero />
            <ContactUsForm />
        </>
    )
}

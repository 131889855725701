// import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import { toast } from 'react-toastify';


export default function TheNavbar({ fileName }) {
    const location = useLocation();
    const [isSticky, setSticky] = useState(false)
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);



    useEffect(() => {

        const handleScroll = () => {

            const offset = window.scrollY;
            if (offset > 0) {
                setSticky(true)
            }
            else {
                setSticky(false)
            }
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    useEffect(() => {
        const closeMobileMenu = () => {
            setMobileMenuOpen(false);
        };

        const handleOutsideClick = (event) => {
            if (isMobileMenuOpen && !event.target.closest('.drawer')) {
                closeMobileMenu();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMobileMenuOpen]);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLinkClick = () => {
        setMobileMenuOpen(false);
    };


    // console.log("from navbar")
    // console.log(fileName)

    return (
        <>

            <div className='fixed top-0 left-0 right-0 z-40'>
                <div className={`hidden lg:flex justify-between items-center lg:px-[108px] lg:py-[16px] 2xl:py-[19px]  bg-white  backdrop-blur-[10px] duration-300 ${isSticky ? 'bg-white bg-opacity-100 duration-300' : 'bg-opacity-30'}`} >

                    <Link to="/">
                        <div className=''>
                            <img className='lg:h-[52px] lg:w-[256px] 2xl:h-[60px] 2xl:w-[268px]' src="./assets/images/navbar/logo.png" alt="" />
                        </div>
                    </Link>
                    <div className='flex items-center gap-6'>
                        <Link to="/">
                            <div className="relative font-bold cursor-pointer text-black-theme lg:text-sm xl:text-base 2xl:text-xl">
                                Home
                                {location.pathname === '/' && (
                                    <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 border-b-[3px] rounded border-black w-2/3"></span>
                                )}
                            </div>
                        </Link>
                        <Link to="/about-us">
                            <div className="relative font-bold cursor-pointer text-black-theme lg:text-sm xl:text-base 2xl:text-xl">
                                About Us
                                {location.pathname === '/about-us' && (
                                    <span className="absolute -bottom-2 left-1/2 transform  -translate-x-1/2 border-b-[3px] rounded border-black w-2/3"></span>
                                )}
                            </div>
                        </Link>
                        <Link to="/our-team">
                            <div className="relative font-bold cursor-pointer text-black-theme lg:text-sm xl:text-base 2xl:text-xl">
                                Our Team
                                {location.pathname === '/our-team' && (
                                    <span className="absolute -bottom-2 left-1/2 transform  -translate-x-1/2 border-b-[3px] rounded border-black w-2/3"></span>
                                )}
                            </div>
                        </Link>
                        <Link to="/contact-us">
                            <div className="relative font-bold cursor-pointer text-black-theme lg:text-sm xl:text-base 2xl:text-xl">
                                Contact Us
                                {location.pathname === '/contact-us' && (
                                    <span className="absolute -bottom-2 left-1/2 transform  -translate-x-1/2 border-b-[3px] rounded border-black w-2/3"></span>
                                )}
                            </div>
                        </Link>
                        <Link to="/member-verification">
                            <div className="relative font-bold cursor-pointer text-black-theme lg:text-sm xl:text-base 2xl:text-xl">
                                Member Verification
                                {location.pathname === '/member-verification' && (
                                    <span className="absolute -bottom-2 left-1/2 transform  -translate-x-1/2 border-b-[3px] rounded border-black w-2/3"></span>
                                )}
                            </div>
                        </Link>
                        {fileName ?
                            <Link to="https://dashboard.overseaschitraliksa.com">
                                <label className="drawer-button btn btn-ghost btn-circle avatar">
                                    <div className="w-10 rounded-full">
                                        <img alt="Tailwind CSS Navbar component"
                                            src={fileName}
                                        />
                                    </div>
                                </label>
                            </Link> 
                            :
                            <Link to="/become-a-member">
                                <div className='bg-theme-black text-white xl:text-base lg:text-xs 2xl:text-lg xl:px-[26px] xl:py-[13px] lg:px-[8px] lg:py-[14px] rounded-lg cursor-pointer'>Become a Member</div>
                            </Link>
                        }
                    </div>

                </div>


                {/* mobile navbar */}
                <div className='flex lg:hidden'>
                    <div className="drawer">
                        <input id="my-drawer" type="checkbox" className="drawer-toggle" checked={isMobileMenuOpen} />
                        <div className="drawer-content">
                            <div className={`flex justify-between px-[30px] py-[15px] duration-300 ${isSticky ? 'bg-white bg-opacity-100 duration-300' : 'bg-white backdrop-blur-[10px] bg-opacity-30'}`}>
                                <img src="./assets/images/navbar/mobile-black-logo.png" alt="" />
                                <label htmlFor="my-drawer" className="drawer-button" onClick={toggleMobileMenu}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 ${isSticky ? 'text-black' : 'text-white'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        <div className="drawer-side w-80">
                            <ul className="min-h-full p-0 bg-white menu pt-7 w-80">
                                <div className='flex items-center justify-between px-6 mb-6'>
                                    <img src="./assets/images/navbar/mobile-white-logo.png" alt="" />
                                    <img onClick={handleLinkClick} src="./assets/images/navbar/drawer-x.png" alt="" />
                                </div>
                                <Link to='/' onClick={handleLinkClick} className={` ${location.pathname === '/' ? 'bg-black text-white' : 'bg-white text-black'}  ps-10 py-3 font-bold `}>
                                    <li >Home</li>
                                </Link>
                                <Link to='/about-us' onClick={handleLinkClick} className={` ${location.pathname === '/about-us' ? 'bg-black text-white' : 'bg-white text-black'}  ps-10 py-2 font-bold `}>
                                    <li >About Us</li>
                                </Link>
                                <Link to='/contact-us' onClick={handleLinkClick} className={` ${location.pathname === '/contact-us' ? 'bg-black text-white' : 'bg-white text-black'}  ps-10 py-2 font-bold `}>
                                    <li >Contact Us</li>
                                </Link>
                                <Link to='/member-verification' onClick={handleLinkClick} className={` ${location.pathname === '/member-verification' ? 'bg-black text-white' : 'bg-white text-black'}  ps-10 py-2 font-bold `}>
                                    <li >Member Verification</li>
                                </Link>
                                <Link to='/become-a-member' onClick={handleLinkClick} className={` ${location.pathname === '/become-a-member' ? 'bg-black text-white' : 'bg-white text-black'}  ps-10 py-2 font-bold `}>
                                    <li >Become a Member</li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>


            </div >


        </>
    )
}

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function OurVisionSection() {
    const [rotationState, setRotationState] = useState(false)

    const handleMousEenter = () => {
        setRotationState(true)
    }
    const handleMouseLeave = () => {
        setRotationState(false)
    }
    return (
        <>
            <div className='px-[22px] py-[15px]  lg:px-[108px] lg:py-[104px] flex flex-col-reverse lg:flex-row justify-between '>
                <div className='lg:w-[520px] xl:w-[640px] 2xl:w-[940px] space-y-3 lg:space-y-4 2xl:space-y-6 mt-8 lg:mt-0'>
                    <div className='font-bold text-theme-black tracking-wide lg:text-xl 2xl:text-3xl'>Our Vision</div>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl font-black lg:w-[80%] 2xl:w-[70%]'>OVERSEAS CHITRALIES COMMUNITY VISION</div>
                    <div className='text-[#230E0E] opacity-90 text-justify lg:text-sm xl:text-base 2xl:text-2xl'>To foster a thriving community of Overseas Chitralis in the Kingdom of Saudi Arabia that exemplifies unity, cultural richness, and resilience. Our vision is to be a dynamic organization that bridges geographical and cultural gaps, promoting understanding, preserving heritage, and creating opportunities for growth and collaboration. Through shared stories, cultural exchange, and community engagement, we aspire to build a harmonious blend of Chitrali traditions with the diverse tapestry of Saudi Arabian society, contributing to a global mosaic of interconnected</div>
                    <Link to="/about-us">
                        <button className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white px-[26px] py-[10px] lg:px-[21px] lg:py-[8px] xl:px-[31px] xl:py-[13px] 2xl:px-[38px] 2xl:py-[17px] lg:text-base 2xl:text-xl rounded-lg tracking-wide'>Learn More</button>
                    </Link>
                </div>

                <div className='text-center relative cursor-pointer hidden lg:block'
                    onMouseEnter={handleMousEenter}
                    onMouseLeave={handleMouseLeave}>
                    <div className='lg:w-[200px] lg:h-[200px] xl:w-[280px] xl:h-[280px] 2xl:w-[360px] 2xl:h-[360px]'><img className={`${rotationState ? 'rotate-90' : ''} duration-300`} src="./assets/images/home-page/circle-border.png" alt="" /></div>
                    <div className='absolute xl:top-4 xl:left-5 lg:top-4 lg:left-4 top-0'> <img className='2xl:w-[258px] 2xl:h-[258px] xl:w-[245px] xl:h-[245px] lg:w-[170px] lg:h-[170px] rounded-full ' src="./assets/images/home-page/our-vision/haider-cm.png" alt="" /></div>

                    <div className='font-bold text-theme-black lg:text-lg 2xl:text-4xl mt-4 2xl:text-start 2xl:ms-12'>Haider Nawaz</div>
                    <div className='text-theme-black lg:text-base 2xl:text-2xl 2xl:text-start 2xl:ms-24'>Chairman</div>
                </div>

                <div className='block lg:hidden mx-auto mt-16'>
                    <img src="./assets/images/home-page/mobile-circle-img.png" alt="" />
                </div>

            </div>
        </>
    )
}

import React from 'react'

export default function OurAimSection() {
    return (
        <>
            <div className='px-[22px] py-[60px] lg:px-[108px] lg:py-[104px] flex justify-between lg:flex-row flex-col-reverse'>
                <div className='lg:w-[530px] xl:w-[530px] 2xl:w-[720px]  space-y-4'>
                    <div className='font-bold text-theme-black tracking-wide lg:text-lg xl:text-xl custom_about_heading mt-4 lg:mt-0'>Our Aim</div>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_sub_heading'>Health, Education, Security and Emergency Assistance</div>
                    <div className='text-[#230E0E] opacity-90 text-justify w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <span className=''>In our tight-knit community, we prioritize the well-being of
                            every individual. Through our platform, we provide a space for sharing health-related
                            information, organizing health camps, and facilitating access to healthcare resources.
                            Together, we strive to ensure a healthier and happier community.</span>

                        <span className=''>Education is the cornerstone of progress. Our community hub serves
                            as a platform to support educational endeavors, whether it's through mentorship
                            programs, scholarship opportunities, or the exchange of knowledge. By empowering
                            each other through education, we aim to build a brighter future for Chitrali
                            individuals and the community as a whole.</span>

                        <span className=''>In times of need, our community stands united. The Chitrali
                            Community Hub is equipped to address security concerns and provide assistance
                            during emergencies. We believe in being there for each other, ensuring that no
                            member of our community feels alone or unsupported.</span>
                    </div>
                </div>
                <div className='lg:w-[520px] xl:w-[520px] 2xl:w-[720px] lg:pr-10 xl:pr-4 2xl:pr-0'>
                    <img className='custom_about_img' src="./assets/images/about-us-page/about-blob-2.png" alt="" />
                </div>
            </div>
        </>
    )
}

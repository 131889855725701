import React from 'react'
import { Link } from 'react-router-dom'

export default function TheCabinet() {
    return (
        <>
            <div className='px-[22px] pt-[60px] lg:px-[108px] lg:pt-[100px] flex flex-col lg:flex-row justify-between items-end'>
                <div className='w-full lg:w-[485px] xl:w-[525px] 2xl:w-[785px] space-y-3  lg:space-y-4 2xl:space-y-6'>
                    <div className='text-theme-black font-bold tracking-wide text-xs lg:text-base 2xl:text-2xl'>KSA Cabinet</div>
                    <div className='text-theme-black font-bold text-base lg:text-xl xl:text-2xl 2xl:text-4xl'>OUR CABINET</div>
                    <div className='text-justify text-stone-900 text-opacity-80 text-xs lg:text-sm xl:text-base 2xl:text-2xl'>People behind the community who are bringing this to the front in order to provide for each other and support the one’s whenever they are in need.</div>
                    {/* <button className='2xl:px-[38px] 2xl:py-[17px] xl:px-[28.11px] xl:py-[9.33px] lg:px-[26.11px] lg:py-[8.33px] px-6 py-2 xl:text-base text-sm lg:text-sm 2xl:text-lg bg-black rounded-[5px] text-white hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer'>Learn More</button> */}
                </div>
                <div>
                    <Link to="/our-team">
                        <div className='px-4 py-2 lg:px-6 lg:py-2 bg-black text-sm lg:text-base text-white rounded-lg cursor-pointer'>View All Team</div>
                    </Link>
                </div>
            </div>
            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/home-page/cabinet-section/cabinet-1.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Haider Nawaz</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Chairman</p>
                </div>
                <div className='text-center'>
                    <div className=''>
                        <img className='rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/home-page/cabinet-section/cabinet-2.png" alt="" />
                    </div>
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Muhammad Aziz</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Chairman</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/home-page/cabinet-section/cabinet-3.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Ali Murad</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Emergency Jirga Secretary</p>
                </div>
                <div className='text-center'>
                    <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' src="./assets/images/home-page/cabinet-section/cabinet-4.png" alt="" />
                    <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-bold text-theme-black'>Mubashir Ahmed</h4>
                    <p className='lg:text-sm 2xl:text-xl text-xs'>Finance & additional secretary</p>
                </div>
            </div>
        </>
    )
}


import React, { useState } from 'react'
import './HeroSection.css'
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

export default function HeroSection() {


    const navigate = useNavigate();


    const [selectedAmount, setSelectedAmount] = useState("$10");

    const [customAmount, SetCustomAmount] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")

    const handleButtonClick = (amount) => {
        setSelectedAmount(amount);
    };



    const handleContribute = async (e) => {

        e.preventDefault()

        let contribute_Data;

        if (customAmount) {
            contribute_Data = {
                customer_amount: customAmount,
                name: name,
                email: email
            };
        } else {
            contribute_Data = {
                fix_amount: selectedAmount,
                name: name,
                email: email
            };
        }

        // Convert the JavaScript object to a JSON string
        const jsonData = JSON.stringify(contribute_Data);

        try {

            if (email.length === 0 || name.length === 0) {
                toast.error("Please fill all fields")
            }

            else {

                // Retrieve the token from localStorage
                const authToken = localStorage.getItem('authToken');

                // Make a POST request using fetch
                const response = await fetch('https://dashboard.overseaschitraliksa.com/api/contribute', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: jsonData
                });

                // if (!response.ok) {
                //     toast.error('Server Error Occured');
                //     // throw new Error('Network response was not ok');
                // }

                const data = await response.json();
                console.log(data); // Handle the data returned by the backend
                toast.success('Please wait for contribution approval!');

            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            toast.error('Please Login First!');

            navigate('/login')
        }
    }




    return (
        <>
            <div className='main_hero flex flex-col lg:flex-row justify-between items-center px-[30px] py-[15px] lg:px-[108px] lg:py-[104px]'>

                {/* Home Page Hero Section Left Section */}
                <div className='space-y-6 mt-20 lg:mt-0'>
                    <div className='tracking-wider uppercase bg-[#00000033] w-[80%] lg:w-[65%] xl:w-[45%] 2xl:w-[40%] rounded-xl text-white px-2 text-center text-sm lg:text-lg 2xl:text-2xl'>Helping and Spreading Love</div>
                    <div className='text-white text-[25px] lg:text-[30px] xl:text-[50px] 2xl:text-[65px] font-bold tracking-wide w-[85%] xl:leading-12'>OVERSEAS CHITRALIES COMMUNITY</div>
                    <div className='text-white text-sm lg:text-lg 2xl:text-2xl w-[90%] lg:w-[57%] 2xl:w-[65%]'>We help Chitrali people in funding, training
                        and support they need in Saudi Arabia</div>
                    <div className='flex items-center gap-3 relative'>
                        <div className="avatar-group -space-x-4 rtl:space-x-reverse">
                            <div className="avatar lg:w-10 lg:h-10 2xl:w-16 2xl:h-16 border-none">
                                <div className="w-8 lg:w-12 2xl:w-16">
                                    <img className='' src="./assets/images/home-page/hero-section/hero-m-1.png" alt='avatar-img' />
                                </div>
                            </div>
                            <div className="avatar lg:w-10 lg:h-10 2xl:w-16 2xl:h-16 border-none">
                                <div className="w-8 lg:w-12 2xl:w-16">
                                    <img src="./assets/images/home-page/hero-section/hero-m-2.png" alt='avatar-img' />
                                </div>
                            </div>
                            <div className="avatar lg:w-10 lg:h-10 2xl:w-16 2xl:h-16 border-none">
                                <div className="w-8 lg:w-12 2xl:w-16">
                                    <img src="./assets/images/home-page/hero-section/hero-m-3.png" alt='avatar-img' />
                                </div>
                            </div>
                            <div className="avatar lg:w-10 lg:h-10 2xl:w-16 2xl:h-16 border-none">
                                <div className="w-8 lg:w-12 2xl:w-16">
                                    <img src="./assets/images/home-page/hero-section/hero-m-4.png" alt='avatar-img' />
                                </div>
                            </div>
                        </div>
                        <div className='text-white'>
                            <p className='font-bold text-sm lg:text-base 2xl:text-xl'>150+</p>
                            <p className='text-sm lg:text-base 2xl:text-xl'>People Contributed</p>
                        </div>
                        <div className='hidden lg:block absolute lg:left-72 2xl:left-[430px]'> <img src="./assets/images/home-page/hero-section/hero-arrow.png" alt="arrow" /></div>
                        <div className='block lg:hidden absolute right-5 rotate-12 top-8'> <img src="./assets/images/home-page/hero-section/hero-arrow-mobile.png" alt="arrow" /></div>
                    </div>
                </div>


                {/* Homepage Hero section Right section */}

                <div className='2xl:h-[495px] 2xl:w-[482px] xl:h-[369px] xl:w-[382px] lg:h-[369px] lg:w-[382px]  bg-white rounded px-5 py-5 lg:px-[40px] lg:py-[28px] space-y-6 mt-20 lg:mt-0'>
                    <div className='font-bold text-center lg:text-xl 2xl:text-3xl tracking-wide uppercase'>Contribute Now</div>

                    <div className='flex justify-around cursor-pointer'>
                        <div
                            className={`text-sm lg:text-sm 2xl:text-2xl border-2 rounded px-2 py-1 lg:px-[5px] lg:py-[5px] 2xl:px-[8px] 2xl:py-[12px] font-bold ${selectedAmount === '$10' ? 'border-black' : 'border-[#E3E3E3] text-[#E3E3E3]'
                                }`}
                            onClick={() => handleButtonClick('$10')}
                        >
                            SR10
                        </div>
                        <div
                            className={`text-sm lg:text-sm 2xl:text-2xl border-2 rounded px-2 py-1 lg:px-[5px] lg:py-[5px] 2xl:px-[8px] 2xl:py-[12px] font-bold ${selectedAmount === '$25' ? 'border-black' : 'border-[#E3E3E3] text-[#E3E3E3]'
                                }`}
                            onClick={() => handleButtonClick('$25')}
                        >
                            SR25
                        </div>
                        <div
                            className={`text-sm lg:text-sm 2xl:text-2xl border-2 rounded px-2 py-1 lg:px-[5px] lg:py-[5px] 2xl:px-[8px] 2xl:py-[12px] font-bold ${selectedAmount === '$50' ? 'border-black' : 'border-[#E3E3E3] text-[#E3E3E3]'
                                }`}
                            onClick={() => handleButtonClick('$50')}
                        >
                            SR50
                        </div>
                        <div
                            className={`text-sm lg:text-sm 2xl:text-2xl border-2 rounded px-2 py-1 lg:px-[5px] lg:py-[5px] 2xl:px-[8px] 2xl:py-[12px] font-bold ${selectedAmount === '$100' ? 'border-black' : 'border-[#E3E3E3] text-[#E3E3E3]'
                                }`}
                            onClick={() => handleButtonClick('$100')}
                        >
                            SR100
                        </div>
                        <div
                            className={`text-sm lg:text-sm 2xl:text-2xl border-2 rounded px-2 py-1 lg:px-[5px] lg:py-[5px] 2xl:px-[12px] 2xl:py-[12px] font-bold ${selectedAmount === '$250' ? 'border-black' : 'border-[#E3E3E3] text-[#E3E3E3]'
                                }`}
                            onClick={() => handleButtonClick('$250')}
                        >
                            SR250
                        </div>
                    </div>
                    <div className='space-y-5'>
                        <input
                            value={customAmount}
                            onChange={(e) => SetCustomAmount(e.target.value)}
                            type="number"
                            placeholder="Custom Amount"
                            className="input border-[#E3E3E3] border-2 focus:outline-none rounded w-full h-[30px] lg:h-[36px] 2xl:h-[56px] ps-3"
                        />
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Name"
                            className="input border-[#E3E3E3] border-2 focus:outline-none rounded w-full  h-[30px] lg:h-[36px] 2xl:h-[56px] ps-3"
                        />

                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            placeholder="Email"
                            className="input border-[#E3E3E3] border-2 focus:outline-none rounded w-full h-[30px] lg:h-[36px] 2xl:h-[56px] ps-3"
                        />

                        <div onClick={handleContribute} className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white text-center py-1 lg:py-[8px] 2xl:py-[16px] rounded-md lg:text-base 2xl:text-xl'><span> SR - </span> <span> Contribute</span></div>
                    </div>
                </div>

            </div>
        </>
    )
}

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'

const ProfessionOption = [
    { label: 'Labour' },
    { label: 'Oil and Gas Industry' },
    { label: 'Construction and Engineering' },
    { label: 'Healthcare Sector' },
    { label: 'Information Technology (IT)' },
    { label: 'Finance and Banking' },
    { label: 'Education' },
    { label: 'Security Officer' },
    { label: 'Hospitality and Tourism' },
    { label: 'Government' },
    { label: 'Retail and Sales' },
    { label: 'Transportation and Logistics' },
    { label: 'Consulting and Professional Services' },
    { label: 'Media and Communications' },
    { label: 'Public Sector' },
];

const emergencyRelationOptions = [
    { label: 'Parents' },
    { label: 'Sibling' },
    { label: 'Relative' },
    { label: 'Friends' },
    { label: 'Other' },
];

const personalCityOption = [
    { label: 'Abu Dhabi' },
    { label: 'Dubai' },
    { label: 'Sharjah' },
    { label: 'Ajman' },
    { label: 'Fujairah' },
    { label: 'Ras Al Khaimah' },
    { label: 'Umm Al Quwain' },
    { label: 'Al Ain' },
    { label: 'Dibba Al-Fujairah' },
    { label: 'Dibba Al-Hisn' },
    { label: 'Khalifa City' },
    { label: 'Al Madam' },
    { label: 'Al Mirfa' },
    { label: 'Al Quoz' },
    { label: 'Al Rafaah' },
    { label: 'Al Sila' },
    { label: 'Al Tawain' },
    { label: 'Dhaid' },
    { label: 'Dibba Al-Hisn' },
    { label: 'Dubai Investment Park' },
    { label: 'Hatta' },
    { label: 'Jebel Ali' },
    { label: 'Liwa Oasis' },
    { label: 'Madinat Zayed' },
    { label: 'Mussafah' },
    { label: 'Ruwais' },
    { label: 'Sila' },
    { label: 'Yas Island' },
    { label: 'Zayed City' }
];

export default function BecomeAMemberForm() {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    // get date from past not future
    const today = new Date().toISOString().split('T')[0];
    const [maxDOBDate, setMaxDOBDate] = useState(today);
    const [maxEntryDate, setMaxEntryDate] = useState(today);
    console.log(setMaxDOBDate)
    console.log(setMaxEntryDate)


    // personal Infor input states
    const [name, setName] = useState("")
    const [fatherName, setFatherName] = useState("")
    const [dob, setDob] = useState("")
    const [saudiNumber, setSaudiNumber] = useState("")
    const [password, setPassword] = useState("")
    const [iqamaNumber, setIqamaNumber] = useState("")
    const [iqamaDesignation, setIqamaDesignation] = useState("")
    const [saudiEntryDate, setSaudiEntryDate] = useState("")
    const [jobOption, setJobOption] = useState("");
    const [cityOption, setCityOption] = useState("");
    const [isAnyRelative, setIsAnyRelative] = useState(false);
    const [relativeFields, setRelativeFields] = useState([{ name: '', relationNumber: '' }]);

    // Pakistan address input states
    const [village, setVillage] = useState("")
    const [pakistanNumber, setPakistanNumber] = useState("")
    const [isMarried, setIsMarried] = useState(false);
    const [numberOfChildren, setNumberOfChildren] = useState('');
    const [anyIllness, setAnyIllness] = useState("");


    // Emergency Contact Input states
    const [emergencyRelationOption, setEmergencyRelationOption] = useState("");
    const [emergencyRelationName, setEmergencyRelationName] = useState("")
    const [emergencyRelationPhone, setEmergencyRelationPhone] = useState("")
    // Image Input states
    // const [fileName, setFileName] = useState('');
    const [previewImage, setPreviewImage] = useState(null);

    // Error States
    const [nameError, setNameError] = useState(false)
    const [fatherNameError, setFatherNameError] = useState(false)
    const [saudiNumberError, setSaudiNumberError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [iqamaNumberError, setIqamaNumberError] = useState(false)
    const [iqamaDesignationError, setIqamaDesignationError] = useState(false)
    const [villageError, setVillageError] = useState(false)
    const [pakistanNumberError, setPakistanNumberError] = useState(false)
    const [emergencyRelationNameError, setEmergencyRelationNameError] = useState(false)
    const [emergencyRelationPhoneError, setEmergencyRelationPhoneError] = useState(false)



    // select input dropdown states
    const [dropdownJobOpen, setDropdownJobOpen] = useState(false);
    const [dropdownCityOpen, setDropdownCityOpen] = useState(false);
    const [dropdownRelationOpen, setDropdownRelationOpen] = useState(false);


    // Dropdown toggles
    const toggleJobDropdown = () => {
        setDropdownJobOpen(!dropdownJobOpen);
        setDropdownCityOpen(false)
    };
    const toggleCityDropdown = () => {
        setDropdownCityOpen(!dropdownCityOpen);
        setDropdownJobOpen(false)
    };
    const toggleRelationDropdown = () => {
        setDropdownRelationOpen(!dropdownRelationOpen);
    };


    // DropDown Option Handles
    const handleJobOptionClick = (option) => {
        setJobOption(option);
        setDropdownJobOpen(false);
    };
    const handleCityOption = (option) => {
        setCityOption(option);
        setDropdownCityOpen(false);
    };

    const handleEmergencyRelationClick = (option) => {
        setEmergencyRelationOption(option);
        setDropdownRelationOpen(false);
    };


    // Single and Married Buttons
    const handleSingleButton = () => {
        setIsMarried(false)
    }
    const handleMarriedButton = () => {
        setIsMarried(true)
    }

    // Number of child handles
    const handleNumberOfChildrenChange = (e) => {
        setNumberOfChildren(e.target.value);
    };




    // No and Yes relation Buttons
    const handleNoRelativeButton = () => {
        setIsAnyRelative(false);
        setRelativeFields([{ name: '', relationNumber: '' }]);
    };
    const handleYesRelativeButton = () => {
        setIsAnyRelative(true);
    };


    // add more field in relation handles
    const handleAddField = () => {
        setRelativeFields([...relativeFields, { name: '', relationNumber: '' }]);
    };

    // Relation input fields changes
    const handleRelationChange = (index, value) => {
        const updatedFields = [...relativeFields];
        updatedFields[index].relationNumber = value;
        setRelativeFields(updatedFields);
    };
    const handleNameChange = (index, value) => {
        const updatedFields = [...relativeFields];
        updatedFields[index].name = value;
        setRelativeFields(updatedFields);
    };

    // toggle password visbility button
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const [file, setFile] = useState(null);
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log(selectedFile);

        if (selectedFile) {
            setFile(selectedFile);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setFile('');
            setPreviewImage(null);
        }
    }

    // Images changes
    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     console.log(file)
    //     setFileName(file)
    //     // if (file) {
    //     //     setFileName(file.name);
    //     //     const reader = new FileReader();
    //     //     reader.onloadend = () => {
    //     //         setPreviewImage(reader.result);
    //     //     };
    //     //     reader.readAsDataURL(file);
    //     // } else {
    //     //     setFileName('');
    //     //     setPreviewImage(null);
    //     // }
    // };


    // All Input Submit Handle
    const handleFormSubmit = async (e) => {


        e.preventDefault();

        try {

            if (name.length < 3) {
                setNameError(true)
                toast.error("Please Fill All Fields")
            }
            else if (fatherName.length < 3) {
                setFatherNameError(true)

            }
            else if (saudiNumber.length < 7) {
                setSaudiNumberError(true)

            }
            else if (password.length < 4) {
                setPasswordError(true)

            }
            else if (iqamaNumber.length < 10) {
                setIqamaNumberError(true)
            }
            else if (iqamaDesignation.length < 4) {
                setIqamaDesignationError(true)
            }
            else if (village.length < 4) {
                setVillageError(true)
            }
            else if (pakistanNumber.length < 10) {
                setPakistanNumberError(true)
            }
            else if (emergencyRelationName.length < 3) {
                setEmergencyRelationNameError(true)
            }
            else if (emergencyRelationPhone.length < 10) {
                setEmergencyRelationPhoneError(true)
            }


            else {

                const formData = new FormData();

                formData.append('name', name);
                formData.append('fatherName', fatherName);
                formData.append('dob', dob);
                formData.append('saudiNumber', saudiNumber);
                formData.append('password', password);
                formData.append('iqamaNumber', iqamaNumber);
                formData.append('iqamaDesignation', iqamaDesignation);
                formData.append('saudiEntryDate', saudiEntryDate);
                formData.append('jobOption', jobOption.label);
                formData.append('cityOption', cityOption.label);
                formData.append('isAnyRelative', isAnyRelative);
                formData.append('village', village);
                formData.append('pakistanNumber', pakistanNumber);
                formData.append('isMarried', isMarried);
                formData.append('numberOfChildren', numberOfChildren);
                formData.append('anyIllness', anyIllness);
                formData.append('emergencyRelationOption', emergencyRelationOption.label);
                formData.append('emergencyRelationName', emergencyRelationName);
                formData.append('emergencyRelationPhone', emergencyRelationPhone);
                formData.append('fileName', file);



                const response = await axios.post("https://dashboard.overseaschitraliksa.com/api/register", formData);

                if (response.status === 200) {
                    console.log(response.data);
                    toast.success('Account Created');
                    console.log(formData)
                    console.log(file)

                    navigate('/login')

                }

            }

        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }


    }

    return (
        <>
            <div className=' px-[22px] py-[50px] lg:px-[108px] lg:py-[100px]'>
                <div className='text-[#8A8A8A] block lg:hidden text-end -mt-8 mb-8'>Already have an account?
                    <Link to='/login'> <span className='text-theme-black font-bold text-xs underline'>Login Instead</span></Link>
                </div>
                <div className='font-bold tracking-wide text-base lg:text-base xl:text-lg'>Registration</div>
                <div className='flex justify-between items-center'>
                    <div className='font-bold text-xl lg:text-[30px] xl:text-[40px] tracking-wide mt-2 lg:mt-4'>Join Us and Secure your Future</div>
                    <div className='text-[#8A8A8A] hidden lg:block'>Already have an account?
                        <Link to='/login'> <span className='text-theme-black font-bold text-lg underline'>Login Instead</span></Link>
                    </div>
                </div>

                <form action="" onSubmit={handleFormSubmit} encType='multipart/form-data'>
                    {/* form field personal information */}
                    <div className='mt-8 lg:mt-16'>
                        <div className='text-theme-black text-lg font-bold tracking-wide'>Personal Information</div>

                        <div>


                            {/* Name */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Name</span>
                                    </div>
                                    <input type="text" placeholder="Enter Name"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={name}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setName(value);
                                            if (value.length < 3) {
                                                setNameError(true);
                                            } else {
                                                setNameError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {nameError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 3 character alteast</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Father name */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Father Name</span>
                                    </div>
                                    <input type="text" placeholder="Enter Father Name"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={fatherName}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFatherName(value);
                                            if (value.length < 3) {
                                                setFatherNameError(true);
                                            } else {
                                                setFatherNameError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {fatherNameError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 3 character atleast</p>
                                        )}
                                    </div>
                                </label>
                            </div>


                            {/* DOB */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Date of birth</span>
                                    </div>
                                    <input
                                        type="date"
                                        placeholder="mm/dd/yy"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black bg-[#F9FAFB]"
                                        max={maxDOBDate}
                                        value={dob}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setDob(value);
                                        }}
                                    />
                                </label>
                            </div>

                            {/* phone number Saudi */}
                            <div className='mt-5'>
                                <label className="form-control relative">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Phone Number (Saudi Arabia)</span>
                                    </div>
                                    <input type="number" placeholder="Enter phone number"
                                        className="input pl-16 p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={saudiNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setSaudiNumber(value);
                                            if (value.length < 7) {
                                                setSaudiNumberError(true);
                                            } else {
                                                setSaudiNumberError(false);
                                            }
                                        }}
                                    />
                                    <div className='absolute bg-white text-[#344153] font-bold top-10 rounded-lg left-1 px-2 py-2 cursor-not-allowed'>+966</div>
                                    <div className='h-2'>
                                        {saudiNumberError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 7 digits atleast</p>
                                        )}
                                    </div>
                                </label>
                            </div>


                            {/* Password */}
                            <div className='mt-5'>
                                <label className="form-control mt-4 relative">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Password</span>
                                    </div>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter your password"
                                        className="input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] py-4"
                                        value={password}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setPassword(value);
                                            if (value.length < 4) {
                                                setPasswordError(true);
                                            } else {
                                                setPasswordError(false);
                                            }
                                        }}
                                    />

                                    {!showPassword ? (
                                        <svg
                                            className='absolute top-[53px] right-3 cursor-pointer'
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="17"
                                            viewBox="0 0 16 17"
                                            fill="none"
                                            onClick={togglePasswordVisibility}
                                        >
                                            <path
                                                d="M8 4C3 4 1 8.5 1 8.5C1 8.5 3 13 8 13C13 13 15 8.5 15 8.5C15 8.5 13 4 8 4Z"
                                                stroke="#344153"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z"
                                                stroke="#344153"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            {/* Extra Diagonal Line */}
                                            <line
                                                x1="3"
                                                y1="3"
                                                x2="13"
                                                y2="13"
                                                stroke="#344153"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                    )
                                        :
                                        (
                                            <svg
                                                className='absolute top-[53px] right-3 cursor-pointer'
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none"
                                                onClick={togglePasswordVisibility}
                                            >
                                                <path
                                                    d="M8 4C3 4 1 8.5 1 8.5C1 8.5 3 13 8 13C13 13 15 8.5 15 8.5C15 8.5 13 4 8 4Z"
                                                    stroke="#344153"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z"
                                                    stroke="#344153"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        )
                                    }
                                    <div className='h-2'>
                                        {passwordError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 4 character or digits atleast</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Iqama Number */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Iqama Number</span>
                                    </div>
                                    <input type="number" placeholder="Enter Iqama Number"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={iqamaNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setIqamaNumber(value);
                                            if (value.length < 10) {
                                                setIqamaNumberError(true);
                                            } else {
                                                setIqamaNumberError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {iqamaNumberError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 10 digits atleast</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Designation in iqama */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Designation in iqama</span>
                                    </div>
                                    <input type="text" placeholder="Enter Designation in iqama"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={iqamaDesignation}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setIqamaDesignation(value);
                                            if (value.length < 4) {
                                                setIqamaDesignationError(true);
                                            } else {
                                                setIqamaDesignationError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {iqamaDesignationError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 4 characters</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Date of Entry in KSAB */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Date of Entry in KSA</span>
                                    </div>
                                    <input max={maxEntryDate} type="date" placeholder="mm/dd/yy"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={saudiEntryDate}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setSaudiEntryDate(value);
                                        }}
                                    />
                                </label>
                            </div>

                            {/* Nature of job */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Nature of Job</span>
                                    </div>
                                </label>
                                <div className="relative inline-block text-left w-full">
                                    <div className='bg-[#F9FAFB] bg-opacity-20'>
                                        <button
                                            type="button"
                                            className="ps-4 inline-flex justify-between items-center w-full rounded-md border  bg-[#F9FAFB] text-sm font-medium focus:border-black"
                                            onClick={toggleJobDropdown}
                                        >
                                            {jobOption ? jobOption.label : 'Select Profession'}
                                            <img src="./assets/icons/select-arrow-2.png" alt="" />
                                        </button>
                                    </div>

                                    {dropdownJobOpen && (
                                        <div className="origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-30" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                {ProfessionOption.map((option) => (
                                                    <button
                                                        key={option.value}
                                                        onClick={() => handleJobOptionClick(option)}
                                                        className="block text-left w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        role="menuitem"
                                                    >
                                                        {option.label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>

                                    )}
                                </div>

                            </div>

                            {/* City */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">City</span>
                                    </div>
                                </label>
                                <div className="relative inline-block text-left w-full">
                                    <div className='bg-[#F9FAFB] bg-opacity-20'>
                                        <button
                                            type="button"
                                            className="ps-4 inline-flex justify-between items-center w-full rounded-md border  bg-[#F9FAFB] text-sm font-medium focus:border-black"
                                            onClick={toggleCityDropdown}
                                        >
                                            {cityOption ? cityOption.label : 'Select City'}
                                            <img src="./assets/icons/select-arrow-2.png" alt="" />
                                        </button>
                                    </div>

                                    {dropdownCityOpen && (
                                        <div className="origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                {personalCityOption.map((option) => (
                                                    <button
                                                        key={option.value}
                                                        onClick={() => handleCityOption(option)}
                                                        className="block text-left w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        role="menuitem"
                                                    >
                                                        {option.label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>

                                    )}
                                </div>

                            </div>



                            {/* Any Relatives/Friend in KSA */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Any Relatives/Friends in KSA</span>
                                    </div>
                                </label>
                                <div className='flex gap-2'>
                                    <button
                                        type='button'
                                        className={`bg-[#F9FAFB] px-3 py-2 rounded-lg ${!isAnyRelative ? 'border border-black text-black' : 'text-[#9AA3B0]'}`}
                                        onClick={handleNoRelativeButton}
                                    >
                                        No
                                    </button>
                                    <button
                                        type='button'
                                        className={`bg-[#F9FAFB]  px-3 py-2 rounded-lg ${isAnyRelative ? 'border border-black' : 'text-[#9AA3B0]'}`}
                                        onClick={handleYesRelativeButton}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>

                            {/* Relatives */}
                            {isAnyRelative && (
                                <>
                                    {relativeFields.map((field, index) => (
                                        <div key={index} className='mt-5'>
                                            <label className="form-control">
                                                <div className="label">
                                                    <span className="label-text font-bold text-black">Name</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Their Name"
                                                    className="input p-4 input-bordered border focus:outline-none border-white focus:border-black bg-[#F9FAFB]"
                                                    value={field.name}
                                                    onChange={(e) => handleNameChange(index, e.target.value)}
                                                />
                                            </label>

                                            {/* phone number Saudi */}
                                            <div className='mt-5'>
                                                <label className="form-control relative">
                                                    <div className="label">
                                                        <span className="label-text font-bold text-black">Phone Number</span>
                                                    </div>
                                                    <input type="text"
                                                        placeholder="Enter phone number"
                                                        className="input pl-16 p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                                        value={field.relationNumber}
                                                        onChange={(e) => handleRelationChange(index, e.target.value)}
                                                    />
                                                    <div className='absolute bg-white text-[#344153] font-bold top-10 rounded-lg left-1 px-2 py-2 cursor-not-allowed'>+966</div>
                                                </label>
                                            </div>
                                        </div>
                                    ))}

                                    {/* Add Field Button */}
                                    <div className='mt-2 text-right font-bold'>
                                        <button
                                            className=""
                                            onClick={handleAddField}
                                        >
                                            + Add Field
                                        </button>
                                    </div>
                                </>
                            )}

                        </div>

                    </div>



                    {/* Pakistan Address */}
                    <div className='mt-12'>
                        <div className='text-theme-black text-lg font-bold tracking-wide'>Pakistan Address</div>

                        <div>


                            {/* Village */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Village</span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Enter Village Name"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={village}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setVillage(value);
                                            if (value.length < 4) {
                                                setVillageError(true);
                                            } else {
                                                setVillageError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {villageError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 4 characters</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Phone Number (Pakistan) */}
                            <div className='mt-5'>
                                <label className="form-control relative">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Phone Number (Pakistan)</span>
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Enter phone number"
                                        className="input pl-16 p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={pakistanNumber}

                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setPakistanNumber(value);
                                            if (value.length < 10) {
                                                setPakistanNumberError(true);
                                            } else {
                                                setPakistanNumberError(false);
                                            }
                                        }}
                                    />
                                    <div className='absolute bg-white text-[#344153] font-bold top-10 rounded-lg left-1 px-2 py-2 cursor-not-allowed'>+92</div>
                                    <div className='h-2'>
                                        {pakistanNumberError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 10 digits</p>
                                        )}
                                    </div>
                                </label>
                            </div>


                            {/* Marital Status */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Marital Status</span>
                                    </div>
                                </label>
                                <div className='flex gap-2'>
                                    <button
                                        type='button'
                                        className={`bg-[#F9FAFB] px-3 py-2 rounded-lg ${!isMarried ? 'border border-black text-black' : 'text-[#9AA3B0]'}`}
                                        onClick={handleSingleButton}
                                    >
                                        Single
                                    </button>
                                    <button
                                        type='button'
                                        className={`bg-[#F9FAFB]  px-3 py-2 rounded-lg ${isMarried ? 'border border-black' : 'text-[#9AA3B0]'}`}
                                        onClick={handleMarriedButton}
                                    >
                                        Married
                                    </button>
                                </div>
                            </div>

                            {/* Children */}
                            {isMarried && (
                                <div className='mt-5'>
                                    <label className="form-control">
                                        <div className="label">
                                            <span className="label-text font-bold text-black">Number of Children</span>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter total number of children"
                                            className="input p-4 input-bordered border focus:outline-none border-white focus:border-black bg-[#F9FAFB]"
                                            value={numberOfChildren}
                                            onChange={handleNumberOfChildrenChange}
                                        />
                                    </label>
                                </div>
                            )}

                            {/*Any Illness */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Any illness</span>
                                    </div>
                                    <textarea
                                        type="text"
                                        placeholder="Describe you illness if any"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] h-24"
                                        value={anyIllness}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setAnyIllness(value);
                                        }}
                                    />
                                </label>
                            </div>


                        </div>
                    </div>

                    {/* Emergency contact */}
                    <div className='mt-12'>
                        <div className='text-theme-black text-lg font-bold tracking-wide'>Emergency Contact</div>

                        <div>

                            {/* Relation */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Relation</span>
                                    </div>
                                </label>
                                <div className="relative inline-block text-left w-full">
                                    <div className='bg-[#F9FAFB] bg-opacity-20'>
                                        <button
                                            type="button"
                                            className="ps-4 inline-flex justify-between items-center w-full rounded-md border  bg-[#F9FAFB] text-sm font-medium focus:border-black"
                                            onClick={toggleRelationDropdown}
                                        >
                                            {emergencyRelationOption ? emergencyRelationOption.label : 'Select Relation'}
                                            <img src="./assets/icons/select-arrow-2.png" alt="" />
                                        </button>
                                    </div>

                                    {dropdownRelationOpen && (
                                        <div className="origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                {emergencyRelationOptions.map((option) => (
                                                    <button
                                                        key={option.value}
                                                        onClick={() => handleEmergencyRelationClick(option)}
                                                        className="block text-left w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        role="menuitem"
                                                    >
                                                        {option.label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                            {/* Name */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Name</span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Enter Their Name"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={emergencyRelationName}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setEmergencyRelationName(value);
                                            if (value.length < 4) {
                                                setEmergencyRelationNameError(true);
                                            } else {
                                                setEmergencyRelationNameError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {emergencyRelationNameError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 3 characters</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Phone Number */}
                            <div className='mt-5'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black">Phone Number</span>
                                    </div>
                                    <input
                                        type="Number"
                                        placeholder="Enter Their Phone Number"
                                        className="input p-4 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]"
                                        value={emergencyRelationPhone}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setEmergencyRelationPhone(value);
                                            if (value.length < 10) {
                                                setEmergencyRelationPhoneError(true);
                                            } else {
                                                setEmergencyRelationPhoneError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {emergencyRelationPhoneError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 10 digits</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {/* Image Input */}
                            <div className='mt-5'>
                                <label className="form-control relative">
                                    <span className="label-text font-bold text-black">Passport Image</span>
                                    <input
                                        type="file"
                                        className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                                        onChange={handleFileChange}
                                        name='fileName'
                                    />
                                    <div className="input pt-3 input-bordered border focus:outline-none border-white focus:border-black bg-[#F9FAFB]">
                                        {file ? file.name : "Upload passport size image"}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-black absolute right-4 top-7 cursor-pointer"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                            />
                                        </svg>
                                    </div>
                                </label>
                            </div>

                            {/* Image Preview */}
                            <div className='mt-3 flex justify-end'>
                                <div className='h-[150px] w-[150px] bg-[#D9D9D9]'>
                                    {previewImage && <img src={previewImage} alt="Preview" className="h-full w-full object-cover" />}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='mt-12'>
                        <div className='text-theme-black text-lg font-bold tracking-wide'>Agreement</div>

                        <div>
                            <p className='text-sm text-black mt-5'>Lorem ipsum dolor sit amet consectetur. Morbi semper risus ut vivamus in. Turpis lobortis cursus et placerat lobortis. Tempor sit purus a vel. Nulla massa ut sit orci urna sit ultrices ac fames. Quisque pretium sit nunc praesent ultricies etiam. Fermentum tellus gravida ut diam risus. Velit purus eget adipiscing velit vitae risus. Leo sit vitae quis ipsum ac. Vestibulum sed tortor gravida ut faucibus faucibus id. Proin lacus.</p>
                            <div className='flex items-center gap-1 text-sm mt-2'>
                                <input id='checkbox' type="checkbox" className=" checkbox-xs lg:checkbox-xs 2xl:checkbox-md rounded lg:rounded-md ms-1 lg:ms-0 cursor-pointer" checked/>
                                <label htmlFor='checkbox' className=' text-[#8A8A8A] tracking-wider 2xl:text-lg mt-1 ms-2 cursor-pointer'> I agree with Terms & Conditions</label>
                            </div>
                        </div>
                    </div>

                    <div className='mt-10'>
                        <input type='submit' value="Register" className='py-3 px-16 bg-theme-black text-white rounded-md cursor-pointer' />
                        <div className='text-[#8A8A8A] mt-6 lg:mt-2 text-sm lg:text-base'> <span>Already have an account? </span>
                            <Link to='/login'><span className='text-theme-black font-bold text-sm lg:text-lg underline'>Login Instead</span></Link>
                        </div>
                    </div>


                </form>

            </div>

        </>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function AboutUsSection() {
    return (
        <>
            <div className='px-[22px] py-[30px] lg:px-[108px] lg:py-[104px] flex justify-between lg:flex-row flex-col relative'>
                <div className=' mt-8 lg:mt-0'>
                    {/* <img className='absolute xl:top-16 xl:left-56 lg:top-24 lg:left-52 xl:h-[155px] xl:w-[155px] lg:h-[95px] lg:w-[95px]' src="./assets/images/home-page/about-us/about-img-1.png" alt="" />
                    <img className='absolute xl:left-24 xl:top-36 lg:left-28 lg:top-36 xl:h-[250px] xl:w-[250px] lg:h-[170px] lg:w-[170px]  ' src="./assets/images/home-page/about-us/about-img-2.png" alt="" />
                    <img className='absolute xl:left-64 xl:top-36 lg:left-56 lg:top-36 xl:h-[251px] xl:w-[251px] lg:h-[171px] lg:w-[171px]' src="./assets/images/home-page/about-us/about-img-3.png" alt="" />
                    <img className='absolute xl:bottom-24 xl:left-56 lg:bottom-36 lg:left-[206px] xl:h-[157px] xl:w-[157px] lg:h-[97px] lg:w-[97px]' src="./assets/images/home-page/about-us/about-img-4.png" alt="" /> */}
                    <img className='mx-auto w-[251px] h-[252px] lg:h-[310px] lg:w-[295px] xl:h-[410px] xl:w-[400px] 2xl:h-[560px] 2xl:w-[540px]' src="./assets/images/home-page/about-us/about-us-full-img.png" alt="" />
                </div>
                <div className='lg:w-[440px] xl:w-[600px] 2xl:w-[840px] space-y-3 xl:space-y-4 lg:space-y-2 2xl:space-y-6'>
                    <div className='font-bold text-theme-black tracking-wide xl:text-xl lg:text-lg 2xl:text-3xl'>About Us</div>
                    <div className='text-theme-black text-lg 2xl:text-4xl xl:text-3xl lg:text-2xl font-black lg:w-[85%] xl:w-[85%] 2xl:w-[80%]'>OVERSEAS CHITRALIES COMMUNITY (SELF HELPING)</div>
                    <div className='text-[#230E0E] opacity-90 text-justify xl:text-base lg:text-sm 2xl:text-2xl'>In the heart of the majestic Hindukush mountains lies Chitral, a region in the Khyber Pakhtunkhwa province of Pakistan. Known for its rich cultural heritage, unique traditions, and breathtaking landscapes, Chitral has produced a diaspora of individuals who have become cultural ambassadors in countries far beyond their mountainous homeland. Among these ambassadors, Chitralis in the Kingdom of Saudi Arabia (KSA) stand out as remarkable representatives, fostering understanding and connection between two distinct cultures.</div>
                    <Link to="/about-us">
                        <button className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white px-[26px] py-[10px] lg:px-[26px] lg:py-[8px] xl:px-[31px] xl:py-[13px] 2xl:px-[38px] 2xl:py-[17px] rounded-lg tracking-wide lg:text-base 2xl:text-xl'>Learn More</button>
                    </Link>
                </div>
            </div>
        </>
    )
}

import React, { useEffect } from 'react'
import BecomeAMemberHero from '../components/BecomeAMemberPageComponents/BecomeAMemberHero'
import BecomeAMemberForm from '../components/BecomeAMemberPageComponents/BecomeAMemberForm'

export default function BecomeAMemberPage() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <BecomeAMemberHero />
            <BecomeAMemberForm />
        </>
    )
}

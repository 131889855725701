import React from 'react'
import './BecomeAMember.css'

export default function BecomeAMemberHero() {
    return (
        <>
            <>
                <div className='become-a-member_hero px-[22px] lg:px-[108px] flex items-center'>
                    <div className='text-white font-bold text-[30px] lg:text-[60px] mt-12 lg:mt-0'>BECOME A MEMBER</div>
                </div>
            </>
        </>
    )
}

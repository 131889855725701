import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';


export default function ContactUsForm() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    // input field state
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    // error state of field
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [subjectError, setSubjectError] = useState(false)
    const [messageError, setMessageError] = useState(false)

    const [blackShadow1, setBlackShadow1] = useState(false)
    const [blackShadow2, setBlackShadow2] = useState(false)

    const handleMouseEnter1 = () => {
        setBlackShadow1(true)

    }
    const handleMouseLeave1 = () => {
        setBlackShadow1(false)

    }
    const handleMouseEnter2 = () => {
        setBlackShadow2(true)
    }
    const handleMouseLeave2 = () => {

        setBlackShadow2(false)
    }

    const handleContactUs = async (e) => {

        e.preventDefault()

        setFirstName(firstName)
        setLastName(lastName)
        setEmail(email)
        setSubject(subject)
        setMessage(message)

        const data = {

            firstName: firstName,
            lastName: lastName,
            email: email,
            subject: subject,
            message: message,
        }

        try {

            if (firstName.length < 3) {
                toast.error("Please Fill All fields")
                setFirstNameError(true)
            }
            else if (lastName.length < 3) {
                toast.error("Please Fill All fields")
                setLastNameError(true)
            }
            else if (!emailRegex.test(email)) {
                toast.error("Please Fill All fields")
                setEmailError(true)
            }
            else if (subject.length < 6) {
                toast.error("Please Fill All fields")
                setSubjectError(true)
            }
            else if (message.length < 20) {

                toast.error("Please Fill All fields")
                setMessageError(true)

            }
            else {

                // Retrieve the token from localStorage
                // const authToken = localStorage.getItem('authToken');

                // Create a config object with headers containing the token
                // const config = {
                //     headers: {
                //         Authorization: `Bearer ${authToken}`,
                //         'Content-Type': 'application/json' // You may need to include other headers as needed by your API
                //     }
                // };

                const response = await axios.post("https://dashboard.overseaschitraliksa.com/api/contact", data);

                if (response.status === 200) {
                    console.log(response.data);
                    toast.success('Message Sent Success!');

                }

                setFirstName("")
                setLastName("")
                setEmail("")
                setSubject("")
                setMessage("")
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }




    return (
        <>
            <div className='px-[22px] py-[50px] lg:px-[108px] lg:py-[100px]'>
                <div className='font-bold tracking-wide text-base lg:text-lg 2xl:text-2xl'>Contact Us</div>
                <div className='font-bold text-[40px] tracking-wide mt-3 text-xl lg:text-base xl:text-3xl 2xl:text-4xl'>Get In Touch With Us</div>

                <div className='mt-12 flex flex-col lg:flex-row gap-24 items-start'>
                    {/* gmail & call section */}
                    <div className='flex flex-row lg:flex-col gap-3 lg:gap-16 mt-8'>
                        <div>
                            <div className={`${blackShadow1 ? 'contact-form-shadow-2' : 'contact-form-card-shadow'} w-[162px] h-[70px] lg:w-[235px] lg:h-[102px] 2xl:w-[335px] 2xl:h-[135px] bg-white rounded-[13.33px] relative flex items-center justify-center `}
                                onMouseEnter={handleMouseEnter1}
                                onMouseLeave={handleMouseLeave1}>

                                <div className='absolute -top-5 lg:left-[92px] 2xl:left-[130px]'>
                                    <img className='h-9 w-9 lg:h-10 lg:w-10 2xl:h-12 2xl:w-12' src='./assets/icons/call-icon.png' alt="" />
                                </div>
                                <div className='text-theme-black font-bold text-sm lg:text-lg 2xl:text-xl w-[80%] lg:w-[70%] text-center leading-5 mt-4'>0300 000 000 0000</div>

                            </div>
                        </div>

                        <div>
                            <div className={`${blackShadow2 ? 'contact-form-shadow-2' : 'contact-form-card-shadow'} w-[162px] h-[70px] lg:w-[235px] lg:h-[102px] 2xl:w-[335px] 2xl:h-[135px] bg-white rounded-[13.33px] relative flex items-center justify-center `}
                                onMouseEnter={handleMouseEnter2}
                                onMouseLeave={handleMouseLeave2}>

                                <div className='absolute -top-5 2xl:left-[130px]'>
                                    <img className='h-9 w-9 lg:h-10 lg:w-10 2xl:h-12 2xl:w-12' src='./assets/icons/gmail-icon.png' alt="" />
                                </div>
                                <div className='text-theme-black font-bold text-sm lg:text-lg 2xl:text-xl w-[80%] lg:w-[70%] text-center leading-5 mt-4'>example@gmail.com</div>

                            </div>
                        </div>

                    </div>


                    {/* form section */}
                    <div className='flex flex-col gap-4'>

                        {/* first and last name */}
                        <div className='flex flex-col lg:flex-row lg:items-center gap-6 '>
                            <div className=' flex-initial w-[22rem] lg:w-60 xl:w-80 2xl:w-[34rem]'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black lg:text-sm 2xl:text-xl">First Name</span>
                                    </div>
                                    <input
                                        type="text" placeholder="Enter First Name" className={` ${firstNameError ? 'border-red-700' : ''} input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] lg:input-md 2xl:input-lg `}
                                        value={firstName}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFirstName(value);
                                            if (value.length < 3) {
                                                setFirstNameError(true);
                                            } else {
                                                setFirstNameError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {firstNameError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Atleast 3 character required</p>
                                        )}
                                    </div>

                                </label>
                            </div>

                            <div className='flex-initial w-[22rem] lg:w-60 xl:w-80 2xl:w-[34rem]'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black lg:text-sm 2xl:text-xl">Last Name</span>
                                    </div>
                                    <input
                                        type="text" placeholder="Enter Last Name" className={` ${lastNameError ? 'border-red-700' : ''} input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] lg:input-md 2xl:input-lg `}
                                        value={lastName}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            setLastName(value)
                                            if (value.length < 3) {
                                                setLastNameError(true);
                                            } else {
                                                setLastNameError(false);
                                            }
                                        }
                                        }
                                    />
                                    <div className='h-2'>
                                        {lastNameError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Atleast 3 character required</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>

                        {/* email and subject */}
                        <div className='flex flex-col lg:flex-row lg:items-center gap-6 '>
                            <div className='flex-initial w-[22rem] lg:w-60 xl:w-80 2xl:w-[34rem]'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black lg:text-sm 2xl:text-xl">Email</span>
                                    </div>
                                    <input
                                        type="email" placeholder="abc@gmail.com" className={` ${emailError ? 'border-red-700' : ''} input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] lg:input-md 2xl:input-lg `}
                                        value={email}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setEmail(value);
                                            if (!emailRegex.test(value)) {
                                                setEmailError(true);
                                            } else {
                                                setEmailError(false);
                                            }
                                        }}
                                    />
                                    <div className='h-2'>
                                        {emailError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter correct email format</p>
                                        )}
                                    </div>
                                </label>
                            </div>

                            <div className='flex-initial w-[22rem] lg:w-60 xl:w-80 2xl:w-[34rem]'>
                                <label className="form-control">
                                    <div className="label">
                                        <span className="label-text font-bold text-black lg:text-sm 2xl:text-xl">Subject</span>
                                    </div>
                                    <input
                                        type="text" placeholder="Enter Subject" className={` ${subjectError ? 'border-red-700' : ''} input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] lg:input-md 2xl:input-lg `}
                                        value={subject}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setSubject(value);
                                            if (value.length < 6) {
                                                setSubjectError(true);
                                            } else {
                                                setSubjectError(false);
                                            }
                                        }
                                        }
                                    />
                                    <div className='h-2'>
                                        {subjectError && (
                                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Atleast 6 character required</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>

                        {/* message area */}
                        <div className=''>
                            <label className="form-control">
                                <div className="label">
                                    <span className="label-text font-bold text-black lg:text-sm 2xl:text-xl">Message</span>
                                </div>
                                <textarea
                                    className={` ${messageError ? 'border-red-700' : ''} textarea h-32 input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB]`}
                                    placeholder="Enter your message"
                                    value={message}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        setMessage(value)
                                        if (value.length < 20) {
                                            setMessageError(true);
                                        } else {
                                            setMessageError(false);
                                        }
                                    }
                                    }
                                ></textarea>
                                <div className='h-2 my-2'>
                                    {messageError && (
                                        <p class=" text-xs text-red-600 dark:text-red-500">Atleast 20 character required</p>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div className=' text-end lg:text-start'>
                            <button onClick={handleContactUs} className='bg-theme-black text-white lg:font-bold py-[11px] px-[49px] lg:px-10 lg:py-3 2xl:px-12 2xl:py-5 lg:text-base 2xl:text-xl rounded-lg'>Send</button>
                        </div>



                    </div>


                </div>
            </div>



        </>
    )
}

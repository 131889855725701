import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaYoutube, } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function TheFooter() {
    return (
        <>
            <div className=' hidden lg:block  lg:px-[108px] lg:pt-[90px] lg:pb-[30px] bg-theme-black relative'>
                <div className='text-white flex justify-between lg:flex-nowrap flex-wrap'>
                    <div className='lg:w-[294px] 2xl:w-[350px]'>
                        <Link to="/"><img className='mb-4 lg:w-[70%] xl:w-[80%] w-[90%]' src="./assets/images/footer/footer-logo.png" alt="" /></Link>
                        <div className='xl:text-base lg:text-sm text-sm 2xl:text-lg px-4 lg:px-4 xl:px-2 mobile_footer'>A self helping intitative at KSA.  Donate us in helping overseas Chitralis Community</div>
                        <div className='text-white mt-4 px-2 font-bold mobile_footer'>Our Newsletter</div>
                        <div className=' flex gap-2 px-2'>
                            <input type="text" placeholder="Enter Email Address" className=" input input-bordered border border-white focus:border-white  bg-black lg:w-[55%] xl:w-[85%]
                             focus:outline-none input-sm lg:input-sm  2xl:input-lg" />
                            <button className='bg-white rounded-md text-black px-6 xl:px-6 lg:px-3 2xl:px-4 font-bold xl:text-base 2xl:text-lg lg:text-sm mobile_footer'>Subscribe</button>
                        </div>
                    </div>
                    <div className='ms-2 lg:ms-0'>
                        <h1 className='font-bold text-lg lg:text-lg 2xl:text-2xl mt-8 lg:mt-0 mb-3 lg:mb-6 mobile_footer'>Menu</h1>
                        <div className='flex flex-col gap-3 text-xs lg:text-base 2xl:text-2xl'>
                            <Link to="/" className=' mobile_footer'>Home</Link>
                            <Link to="/about-us" className=' mobile_footer'>About Us</Link>
                            <Link to="contact-us" className=' mobile_footer'>Contact Us</Link>
                            <Link to="/member-verification" className=' mobile_footer'>Member Verification</Link>
                            <Link to="/become-a-member" className=' mobile_footer'>Become Member</Link>

                        </div>
                    </div>
                    <div className='text-start lg:text-start'>
                        <h1 className='font-bold text-lg lg:text-lg 2xl:text-2xl mt-8 lg:mt-0 mb-3 lg:mb-6 mobile_footer'>Contact Us</h1>
                        <div className='flex flex-col gap-3 text-xs lg:text-base 2xl:text-2xl'>
                            <div className='mobile_footer'>(000)-1234567890</div>
                            <div className='mobile_footer'>example@gmail.com</div>
                            <div className='w-[60%] lg:w-[60%] text-[13px] mobile_footer'>Street No: 12, House No. 123, Saudi Arabia</div>
                        </div>
                    </div>
                    <div>
                        <h1 className='font-bold text-lg lg:text-lg 2xl:text-2xl mt-8 lg:mt-0 mb-3 lg:mb-6 mobile_footer'>Social</h1>
                        <div className='flex flex-col gap-3 text-xs lg:text-base 2xl:text-2xl'>
                            <a href="/" className='flex gap-2 items-center mobile_footer'><FaFacebook /> Facebook</a>
                            <a href="/" className='flex gap-2 items-center mobile_footer'><FaXTwitter /> Twitter</a>
                            <a href="/" className='flex gap-2 items-center mobile_footer'><FaInstagram /> Instagram</a>
                            <a href="/" className='flex gap-2 items-center mobile_footer'><FaYoutube /> Youtube</a>
                        </div>
                    </div>
                </div>

                <div className='border border-white mt-8'>
                </div>

                <Link to="https://bingtechs.com/" target="_blank" rel="noopener noreferrer">
                    <div className='text-center text-white mt-4 lg:text-base 2xl:text-xl mobile_footer'>
                        &copy; 2024 Design and Developed by Bingtechs
                    </div>
                </Link>

                <div className='absolute bottom-0 left-0 '>
                    <img className='xl:w-[198px] xl:h-[222px] lg:w-[178px] lg:h-[192px]' src="./assets/images/footer/finger-print.png" alt="" />
                </div>

            </div>


            {/* Mobile footer */}
            <div className='block lg:hidden bg-theme-black '>
                <div className=' px-[22px] pt-[50px] pb-[30px]  '>
                    <div>
                        <img className='w-[280px] h-[52px]' src="./assets/images/footer/footer-logo.png" alt="" />
                        <div className='text-white text-sm mt-4 mobile_footer'>A self helping intitative at KSA.  Donate us in helping overseas Chitralis Community</div>
                        <div className='text-white mt-4 font-semibold mb-2 mobile_footer'>Our Newsletter</div>
                        <div className=' space-x-2'>
                            <input type="text" placeholder="Enter Email Address" className=" input input-bordered rounded border border-white focus:border-white  bg-black h-8
                             focus:outline-none input-md w-[13rem]" />
                            <button className='bg-white rounded-md text-theme-black font-bold tracking-wide px-6 py-[6px] text-sm'>Subscribe</button>
                        </div>

                        <div className='text-white flex gap-8 mt-8'>
                            <div className=''>
                                <h1 className='text-2xl mobile_footer'>Menu</h1>
                                <p className='text-sm mt-2 mobile_footer'>Home</p>
                                <p className='text-sm mt-2 mobile_footer'>About Us</p>
                                <p className='text-sm mt-2 mobile_footer'>Contact Us</p>
                                <p className='text-sm mt-2 mobile_footer'>Member Verification</p>
                                <p className='text-sm mt-2 mobile_footer'>Become Member</p>
                            </div>
                            <div className=''>
                                <h1 className='text-2xl mobile_footer'>Contact Us</h1>
                                <p className='text-sm mt-2 mobile_footer'>(000)-1234567890</p>
                                <p className='text-sm mt-2 mobile_footer'>example@gmail.com</p>
                                <p className='text-sm mt-2 mobile_footer'>Street No: 12, House No. <br /> 123, Saudi Arabia</p>
                            </div>
                        </div>

                        <div className='relative'>
                            <div className='text-white'>
                                <h1 className='text-2xl mt-6 mb-3 mobile_footer'>Socials</h1>
                                <div className='flex text-sm gap-6'>
                                    <a href="/" className='flex gap-2 items-center mobile_footer'><FaFacebook /> Facebook</a>
                                    <a href="/" className='flex gap-2 items-center mobile_footer'><FaXTwitter /> Twitter</a>
                                </div>
                                <div className='flex text-sm gap-6 mt-4 mb-4'>
                                    <a href="/" className='flex gap-2 items-center mobile_footer'><FaInstagram /> Instagram</a>
                                    <a href="/" className='flex gap-2 items-center mobile_footer'><FaYoutube /> Youtube</a>
                                </div>
                            </div>

                            <img className='absolute -top-[55px] -right-5' src="./assets/images/footer/finger-print-mobile.png" alt="" />
                        </div>



                    </div>
                </div>

                <div className='text-white'>
                    <hr className='text-white' />
                    <div className='text-sm text-center pt-[20px] pb-[30px] mobile_footer'>&copy; 2024 Design and Developed by Bingtechs</div>
                </div>

            </div>





        </>
    )
}
